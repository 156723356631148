<template>
    <div class="single_page" v-loading.fullscreen="fullscreenLoading" element-loading-text="加载中...">
        <!-- 操作栏 -->
        <div class="operation">
            <el-tooltip class="box-item" effect="dark" content="显示标签" placement="bottom" v-if="viewMode === 'chart'">
                <div class="show_name" :class="{ active: scatterLabelShow === 'Y' }" @click="scatterLabelShow = scatterLabelShow === 'Y' ? 'N' : 'Y'"><i class="iconfont icon-label"></i></div>
            </el-tooltip>
            <el-radio-group v-model="viewMode" style="margin-left: 15px">
                <el-radio-button label="chart">
                    <Histogram style="width: 1.2em; height: 1.2em" />
                </el-radio-button>
                <el-radio-button label="table">
                    <Grid style="width: 1.2em; height: 1.2em" />
                </el-radio-button>
            </el-radio-group>
            <el-button type="primary" style="margin-left: 15px" v-if="viewMode === 'chart' && !showExportScreen" @click="openExportScreen">导出筛选</el-button>
            <el-button type="primary" style="margin-left: 15px" v-if="viewMode === 'chart' && showExportScreen" @click="closeExportScreen">导出筛选</el-button>
            <el-button type="primary" style="margin-left: 15px" @click="handleDownload($route.query.id, 1)">导出为excel</el-button>
        </div>
        <!-- 图表 -->
        <div class="chart_row" v-show="viewMode === 'chart'">
            <div class="chart_box" id="chart_box"></div>
            <div class="export_choose" v-show="showExportScreen">
                <div class="export_choose_box">
                    <div class="tit_box">
                        <div class="le">
                            <span>筛选范围</span>
                            <el-tooltip effect="dark" :content="`点击图表${task?.analysisDimension}选中`" placement="left">
                                <InfoFilled class="icon" />
                            </el-tooltip>
                        </div>
                        <div class="ri">
                            <el-tooltip effect="dark" content="区域选择" placement="top" v-if="!isBrush">
                                <div class="rect" @click="handleAreaSelect"><div class="circle"></div></div>
                            </el-tooltip>
                            <div class="rect" v-if="isBrush" @click="handleAreaSelectConfirm"><i class="iconfont icon-duihao"></i></div>
                            <div class="rect" v-if="isBrush" @click="handleAreaSelectClose"><i class="iconfont icon-cuowuguanbiquxiao"></i></div>
                        </div>
                    </div>
                    <div class="list scrollbar">
                        <div class="li" v-for="(item, index) in exportScreenList" :key="item.name">
                            <span>{{ item.name }}</span>
                            <CircleCloseFilled class="close" @click.stop="removeSelectedPoint(index)" />
                        </div>
                        <p class="no_data" v-if="!exportScreenList?.length">请点击图表{{ task?.analysisDimension }}选中</p>
                    </div>
                    <div class="export_choose_btn">
                        <div class="btn" @click="closeExportScreen">关闭</div>
                        <div class="btn" @click="clearSelectedPoint">清空</div>
                        <div class="btn" @click="onScreenExport">确认导出</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="chart_table_box" v-show="viewMode === 'chart'">
            <RatioTable :data="ratioData" />
        </div>
        <!-- 表格 -->
        <div class="table_box" v-show="viewMode === 'table'">
            <el-table :data="tableData" border style="width: 100%" :header-cell-style="headerCellStyle" :cell-style="cellStyle" height="600">
                <el-table-column prop="brandName" label="品牌" fixed="left" width="180" />
                <el-table-column prop="egpmFlag" label="EGPM指标" fixed="left" width="100" align="center" />
                <el-table-column prop="egpmGjScore" label="EGPM规模得分" fixed="left" width="100" align="center" />
                <el-table-column prop="egpmXqScore" label="EGPM热度得分" fixed="left" width="100" align="center" />
                <el-table-column prop="batchTotal" label="连续期数" fixed="left" width="80" align="center" />
                <el-table-column prop="totalScore" label="总得分" width="100" align="center" />
                <el-table-column prop="shopNumScore" label="门店排序得分" width="100" align="center" />
                <el-table-column prop="cityNumScore" label="城市排序得分" width="100" align="center" />
                <el-table-column prop="tierRatioScore" label="1-3线城市门店数占比排序得分" width="100" align="center" />
                <el-table-column prop="centerRatioScore" label="集中度排序得分" width="100" align="center" />
                <el-table-column prop="scopeScore" label="规模总得分" width="100" align="center" />
                <el-table-column prop="shopIncRatioScore" label="门店增长率排序得分" width="100" align="center" />
                <el-table-column prop="provIncRatioScore" label="省份增长率排序得分" width="100" align="center" />
                <el-table-column prop="cityIncRatioScore" label="城市增长率排序得分" width="100" align="center" />
                <el-table-column prop="tierNewRatioScore" label="1-3线新店占比排序得分" width="100" align="center" />
                <el-table-column prop="growthScore" label="成长性总得分" width="100" align="center" />
                <el-table-column prop="avgShopAgeScore" label="平均店龄排序得分" width="100" align="center" />
                <el-table-column prop="closeRatioScore" label="关店率排序得分" width="100" align="center" />
                <el-table-column prop="stabilityScore" label="稳定性总得分" width="100" align="center" />
                <el-table-column prop="shopNum" label="门店数" width="80" align="center" />
                <el-table-column prop="cityNum" label="城市数" width="80" align="center" />
                <el-table-column prop="tierRatio" label="1-3线城市门店数占比" width="80" align="center" />
                <el-table-column prop="centralRatio" label="集中度" width="80" align="center" />
                <el-table-column prop="shopIncRatio" label="门店增长率" width="100" align="center" />
                <el-table-column prop="provIncRatio" label="省份增长率" width="80" align="center" />
                <el-table-column prop="cityIncRatio" label="城市增长率" width="80" align="center" />
                <el-table-column prop="newTierRatio" label="1-3线新店占比" width="100" align="center" />
                <el-table-column prop="avgShopAge" label="平均店龄" width="100" align="center" />
                <el-table-column prop="closeRatio" label="关店率" width="100" align="center" />
                <el-table-column prop="brandId" label="品牌ID" width="80" align="center" />
                <el-table-column prop="batch" label="批次" width="80" align="center" />
            </el-table>
            <div class="page_box">
                <el-pagination layout="prev, pager, next, total, jumper" background :total="total" :page-size="query.pageSize" v-model:current-page="query.pageNum"></el-pagination>
            </div>
        </div>
    </div>
    <DishDialog :show="show" @close="close" :dish="currentDish" :chartData="chartData" />
</template>

<script setup>
import { ref, watch, nextTick, inject } from "vue";
import { Histogram, Grid, InfoFilled, CircleCloseFilled } from "@element-plus/icons-vue";
import useChart from "./useChart";
import useData from "./useData";
import DishDialog from "./dish-dialog/index.vue";
import useDishDialog from "./dish-dialog/useDishDialog";
import useResultStore from "@/store/egpm-brand/useResultStore";
// import TableLineChart from "@/components/egpm/TableLineChart.vue";
import useDownload from "../useDownload";
import useTableStyle from "./useTableStyle";
import RatioTable from "./comp/RatioTable.vue";
import useExportChartScreen from "./useExportChartScreen";

const task = inject("task");
const resultStore = useResultStore();
const viewMode = ref("chart"); // 展示方式 chart | table
const showExportScreen = ref(false); // 是否显示导出筛选
const exportScreenList = ref([]); // 选中的导出筛选列表

// 注册主组件搜索事件
resultStore.setSearchEvent(() => {
    tableSearch(); // 获取表格数据
    getChartData(); // 获取图表数据
});

// 如果从table切换到chart，需要重新初始化图表,防止在table时搜索过数据导致数据不一致
watch(
    () => viewMode.value,
    (val, old) => {
        if (val === "chart" && old === "table") {
            nextTick(() => {
                initChart(chartData.value);
            });
        }
    }
);

// 单菜弹框
const { show, open, close, currentDish } = useDishDialog();

// 图表
const { handleResize, initChart, scatterLabelShow, renderExportScreen, handleAreaSelect, isBrush, handleAreaSelectClose, handleAreaSelectConfirm } = useChart({ open, showExportScreen, exportScreenList });

// 数据
const { query, total, tableData, search: tableSearch, getChartData, chartData, fullscreenLoading, ratioData } = useData({ resultStore, initChart, viewMode });

// 创建下载任务
const { handleDownload } = useDownload({ resultStore });

// 表格样式
const { headerCellStyle, cellStyle } = useTableStyle();

// 导出图表筛选
const { openExportScreen, closeExportScreen, removeSelectedPoint, clearSelectedPoint, onScreenExport } = useExportChartScreen({ showExportScreen, handleResize, exportScreenList, renderExportScreen, handleDownload, handleAreaSelectClose });
</script>

<style lang="scss" scoped>
.single_page {
    .operation {
        display: flex;
        justify-content: flex-end;
        .show_name {
            height: 40px;
            border: 1px solid #dcdfe6;
            border-radius: 4px;
            display: flex;
            align-items: center;
            padding: 0 10px;
            color: #606266;
            cursor: pointer;
            margin-left: 15px;
            .iconfont {
                font-size: 18px;
                color: #666;
            }
            &.active {
                background-color: var(--primary-color);
                border-color: var(--primary-color);
                .iconfont {
                    color: #fff;
                }
            }
            &:not(.active):hover {
                .iconfont {
                    color: var(--primary-color);
                }
            }
        }
    }
    .chart_row {
        height: 600px;
        display: flex;
        .chart_box {
            flex: 1;
            height: 100%;
        }
        .export_choose {
            width: 240px;
            padding-top: 30px;
            padding-bottom: 46px;
            .export_choose_box {
                --header-height: 40px;
                height: 100%;
                position: relative;
                border-radius: 4px;
                overflow: hidden;
                padding-top: var(--header-height);
                padding-bottom: 40px;
                .tit_box {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background-color: var(--primary-color);
                    height: var(--header-height);
                    padding: 0 15px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    .le {
                        display: flex;
                        align-items: center;
                        span {
                            color: #fff;
                        }
                        .icon {
                            width: 1.2em;
                            height: 1.2em;
                            color: #fff;
                            margin-left: 8px;
                            cursor: pointer;
                        }
                    }
                    .ri {
                        display: flex;
                        .rect {
                            width: 22px;
                            height: 16px;
                            border: 2px solid #fff;
                            border-radius: 2px;
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: #fff;
                            margin-left: 5px;
                            .iconfont {
                                font-size: 12px;
                            }
                            .circle {
                                width: 6px;
                                height: 6px;
                                background-color: #fff;
                                border-radius: 50%;
                            }
                        }
                    }
                }
                .list {
                    height: 100%;
                    overflow-y: auto;
                    border-left: 1px solid #ccc;
                    border-right: 1px solid #ccc;
                    border-bottom: 1px solid #ccc;
                    .li {
                        height: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 0 15px;
                        border-bottom: 1px solid #f2f2f2;
                        &:last-child {
                            border-bottom: none;
                        }
                        span {
                            color: #666;
                        }
                        .close {
                            width: 16px;
                            height: 16px;
                            color: #999;
                            display: block;
                            cursor: pointer;
                        }
                        &:hover {
                            .close {
                                display: block;
                            }
                        }
                    }
                    .no_data {
                        text-align: center;
                        padding: 15px 0;
                        color: #999;
                        font-size: 14px;
                    }
                }
                .export_choose_btn {
                    height: 40px;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    overflow: hidden;
                    border-left: 1px solid #ccc;
                    border-bottom: 1px solid #ccc;
                    border-radius: 0 0 4px 4px;
                    .btn {
                        border-right: 1px solid #ccc;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 33.3%;
                        cursor: pointer;
                        color: var(--primary-color);
                        &:hover {
                            background-color: var(--primary-color);
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
    .chart_table_box {
        margin-top: 15px;
    }
    .table_box {
        margin-top: 15px;
        .page_box {
            padding-top: 30px;
            padding-bottom: 15px;
            display: flex;
            justify-content: flex-end;
        }
        .el-table--border {
            border-color: #ccc;
        }
    }
}
</style>
