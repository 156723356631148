import { nextTick } from 'vue';
import { ElMessage } from 'element-plus';
import { useRoute } from 'vue-router';

export default ({ showExportScreen, handleResize, exportScreenList, renderExportScreen, handleDownload, handleAreaSelectClose }) => {

    const route = useRoute();

    // 打开导出筛选
    function openExportScreen() {
        showExportScreen.value = true;
        nextTick(handleResize);
    }

    // 关闭导出筛选
    function closeExportScreen() {
        showExportScreen.value = false;
        clearSelectedPoint();// 清除选中点
        handleAreaSelectClose();// 关闭区域选择
        nextTick(handleResize);
    }

    // 移除选中点
    function removeSelectedPoint(index) {
        exportScreenList.value.splice(index, 1);
        renderExportScreen();// 重新渲染点
    }

    // 清空
    function clearSelectedPoint() {
        exportScreenList.value = [];
        renderExportScreen();// 重新渲染点
    }

    // 点击导出
    function onScreenExport() {
        if (!exportScreenList.value?.length) {
            ElMessage.error('筛选范围不能为空');
            return;
        }
        const items = exportScreenList.value.map(item => {
            const res = { itemName: item.brandName }
            return res;
        });
        handleDownload(route.query.id, 1, { items });
        closeExportScreen();
    }

    return { openExportScreen, closeExportScreen, removeSelectedPoint, clearSelectedPoint, onScreenExport }
}