import { reactive, ref, watch, onBeforeMount, computed } from 'vue';
import { apiEgpmTaskResultSinglePage, apiEgpmTaskResultSingleStats, apiEgpmTaskResultStatsRatio } from '@/request/egpmBrandApi';
import { useRoute } from 'vue-router';

export default ({ resultStore, initChart, viewMode }) => {

    const route = useRoute();
    const query = reactive({
        pageNum: 1,
        pageSize: 10,
    })
    const total = ref(0);
    const tableData = ref([]); // 表格数据
    const chartData = ref({});// 图表数据
    const ratioData = ref({});// 准确性数据
    const chartLoading = ref(false);
    const tableLoading = ref(false);
    const fullscreenLoading = computed(() => {
        return chartLoading.value || tableLoading.value
    })

    // 获取表格数据
    async function getTableData() {
        tableLoading.value = true;
        try {
            const params = { ...resultStore.sreen, ...query, taskId: route.query.id }
            const { data } = await apiEgpmTaskResultSinglePage(params);
            total.value = data?.total || 0;
            tableData.value = data?.list || [];
        } finally {
            tableLoading.value = false;
        }
    }

    // 表格搜索
    function search() {
        query.pageNum = 1;
        getTableData();
    }

    // 分页
    watch(() => query.pageNum, getTableData)

    // 获取图表数据
    async function getChartData() {
        chartLoading.value = true;
        try {
            const params = { ...resultStore.sreen, taskId: route.query.id, l: 10000 }
            const { data } = await apiEgpmTaskResultSingleStats(params);
            chartData.value = data || {};
            if (viewMode.value === 'chart') {
                initChart(data || {})
            }
        } finally {
            chartLoading.value = false;
        }
    }

    // 获取图表下面的table数据
    async function getChartTableData() {
        const params = { taskId: route.query.id, }
        const { data } = await apiEgpmTaskResultStatsRatio(params);
        ratioData.value = data || {};

    }

    onBeforeMount(() => {
        getTableData();// 获取表格数据
        getChartData();// 获取图表数据
        getChartTableData();// 获取图表下面的table数据
    })

    return { query, total, tableData, search, getChartData, chartData, fullscreenLoading, ratioData }
}