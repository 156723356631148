export default () => {

    // 需要头部加粗的字段
    const blodProperty = [
        'egpmFlag',// EGPM指标
        'egpmGjScore',// EGPM规模得分
        'egpmXqScore',// EGPM热度得分
        'batchTotal',// 连续期数
    ]

    // 黄色背景单元格字段 FEF2CC
    const yellowProperty = [
    ]

    // 蓝色背景单元格字段 D9E2F3
    const blueProperty = [
        'egpmFlag',// EGPM指标
        'egpmGjScore',// EGPM规模得分
        'egpmXqScore',// EGPM热度得分
        'batchTotal',// 连续期数
    ]

    // 表格头部样式
    function headerCellStyle({ column }) {
        const res = {
            fontWeight: 'normal',
            background: '#f2f2f2',
            borderColor: '#ccc',
            color: '#666'
        }
        // 是否加粗
        if (blodProperty.includes(column.property)) {
            res.fontWeight = 'bold';
            res.color = '#000';
        }
        // 黄色背景
        if (yellowProperty.includes(column.property)) {
            res.background = '#FEF2CC';
        }
        // 蓝色背景
        if (blueProperty.includes(column.property)) {
            res.background = '#D9E2F3';
        }
        return res;
    }

    // 单元格样式
    function cellStyle({ column }) {
        const res = {
            background: '#fff',
            borderColor: '#ccc',
            height: '57px'
        }
        // 黄色背景
        if (yellowProperty.includes(column.property)) {
            res.background = '#FEF2CC';
        }
        // 蓝色背景
        if (blueProperty.includes(column.property)) {
            res.background = '#D9E2F3';
        }
        return res;
    }

    return { headerCellStyle, cellStyle }
}