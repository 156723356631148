<template>
    <el-dialog :model-value="show" :title="dish.name" width="95%" top="5vh" :close-on-click-modal="false" @close="handleClose" @opened="handleOpened">
        <div class="details_dialog_content" v-loading="loading" element-loading-background="rgba(255,255,255,.4)">
            <div class="chart">
                <div class="dish_chart_box" id="dish_chart_box"></div>
                <div class="pro_chart_box" id="pro_chart_box"></div>
                <div class="city_chart_box" id="city_chart_box"></div>
            </div>
        </div>
    </el-dialog>
</template>

<script setup>
import { useRoute } from "vue-router";
import { ref, computed } from "vue";
import useLineChart from "./useLineChart";
import useProChart from "./useProChart";
import useCityChart from "./useCityChart";

const route = useRoute();
const props = defineProps({
    show: Boolean,
    dish: { type: Object, default: () => {} },
    chartData: { type: Object, default: () => {} },
    handleDownload: Function,
});
const emits = defineEmits(["close"]);

const batch = ref(""); // 折线图选中的batch
const province = ref(""); // 选中的省份
const loading = computed(() => {
    const loadingList = [leLoading.value, proLoading.value, cityLoading.value];
    return loadingList.some((item) => item);
});

// 折线图
const { leLoading, getData, handleLineDispose } = useLineChart({ props, route, batch });

// 省份图
const { proLoading, getData: getProData, handleDispose } = useProChart({ props, route, batch, province, getCityData: () => getCityData() });

// 城市图
const { cityLoading, handleDispose: handleCityDispose, getData: getCityData } = useCityChart({ props, route, batch, province });

// 打开弹窗
function handleOpened() {
    getData();
    getProData();
}

function handleClose() {
    handleLineDispose(); // 销毁折线图
    handleDispose(); // 销毁省份柱状图
    handleCityDispose(); // 销毁城市柱状图
    batch.value = ""; // 清空选中batch
    province.value = ""; // 清空选中province
    emits("close");
}
</script>

<style lang="scss" scoped>
.details_dialog_content {
    height: 600px;
    .chart {
        display: flex;
        height: 100%;
        .dish_chart_box {
            width: 40%;
            height: 100%;
        }
        .pro_chart_box,
        .city_chart_box {
            width: 30%;
            height: 100%;
        }
    }
}
</style>
