import { ref } from 'vue';

export default () => {

    const show = ref(false);
    const currentDish = ref({});

    function open(data) {
        if (!data?.name) return;
        currentDish.value = data || {};
        show.value = true;
    }

    function close() {
        currentDish.value = {};
        show.value = false;
    }

    return { show, open, close, currentDish }
}